@tailwind base;
@tailwind components;
@tailwind utilities;
@import "daisyui/dist/full.css";
@import '@fortawesome/fontawesome-free/css/all.min.css';


/* Markdown Styles */
.markdown-content {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
  }
  
  .markdown-content h1 {
    font-size: 32px;
    border-bottom: 1px solid #eee;
  }
  
  .markdown-content h2 {
    font-size: 24px;
    margin: 1.5em 0 1em;
    border-bottom: 1px solid #eee;
  }
  
  .markdown-content h3 {
    font-size: 20px;
    margin: 1.5em 0 1em;
  }
  
  .markdown-content p {
    margin: 1em 0;
  }
  
  .markdown-content ul {
    margin: 1em 0;
    padding-left: 20px;
  }
  
  .markdown-content ol {
    margin: 1em 0;
    padding-left: 20px;
  }
  
  .markdown-content li {
    margin: 0.5em 0;
  }
  
  .markdown-content pre {
    background-color: #f8f8f8;
    padding: 1em;
    border-radius: 4px;
    overflow-x: auto;
  }
  
  .markdown-content code {
    font-family: Consolas, monospace;
    background-color: #f8f8f8;
    padding: 0.2em 0.4em;
    border-radius: 4px;
  }
  
  .markdown-content blockquote {
    color: #666;
    border-left: 4px solid #ccc;
    padding: 0.5em 1em;
    margin: 1em 0;
  }
  
  .markdown-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .markdown-content a:hover {
    text-decoration: underline;
  }